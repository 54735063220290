<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-12">
        <div class="row d-flex align-items-center">
          <div class="col-6 col-sm-6">
            <form class="search-form" @submit.prevent="doSearch()">
              <div class="input-group border rounded-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text border-0 rounded-sm">
                    <FeatherIcon type="search" class="icon-md cursor-pointer" />
                  </div>
                </div>
                <input v-model="search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
              </div>
            </form>
          </div>
          <div class="col-3 col-sm-3">
            <button v-if="checkPermission.create" class="btn btn-primary" @click="addAgent">Add Agent</button>
          </div>
        </div>
        <p class="text-muted mt-3">Agents</p>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Last Login Date</th>
                <th>Availibility Status</th>
                <th>Last Online Date</th>
                <th>Last Offline Date</th>
                <th>Assigned Group</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="agent in agentList" :key="agent.id" style="cursor: pointer">
                <td>{{ agent.user.name }}</td>
                <td>{{ agent.user.email }}</td>
                <td>{{ agent.last_login_at_str }}</td>
                <td>
                  <el-badge is-dot :type="agent.is_available ? 'success' : 'danger'"></el-badge>
                  {{ agent.is_available ? 'Online' : 'Offline' }}
                </td>
                <td>{{ agent.last_online_at_str }}</td>
                <td>{{ agent.last_offline_at_str }}</td>
                <td>{{ agent.agent_groups.map(v => v.name).join(", ") }}</td>
                <td>
                  <button v-if="checkPermission.delete" class="btn btn-outline-primary btn-sm mr-2" @click="deleteAgent(agent.id)">
                    <font-awesome icon="times"/>
                  </button>
                  <button v-if="checkPermission.update" class="btn btn-outline-primary btn-sm" @click="handlerBtnSetAgentAvail(agent.id, agent.is_available)">
                    <font-awesome icon="power-off"/>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <el-empty v-if="totalRow == 0" description="No data"></el-empty>
        <b-pagination v-if="totalRow > 10" v-model="currentPage" :total-rows="totalRow" :per-page="10"/>
      </div>
    </div>
    <b-modal v-model="showModalAgent" title="Add Agent" hide-footer>
      <b-form @submit.prevent="asignUser" @reset="closeModalAgent">
        <b-form-group
          id="user-group"
          label="User:"
          label-for="user">
          <el-select
            class="w-100"
            id="user"
            filterable
            :filter-method="handlerSearchUser"
            v-model="formAgent.user_id">
            <el-option v-for="user in userList" :key="user.value" :label="user.text" :value="user.value"></el-option>
          </el-select>
        </b-form-group>

        <b-form-group
          id="agent-group-group"
          label="Group:"
          label-for="agent-group"
          class="position-relative">
          <button
            class="btn btn-primary btn-sm mb-1 position-absolute"
            style="top: 0; right: 0;"
            @click.prevent="showModalAgentGroup = true">
            Add Group
          </button>
          <el-select
            class="mt-2 w-100"
            id="agent-group"
            filterable
            remote
            @change="handlerSelectedGroupAgent"
            :filter-method="handlerSearchAgentGroup"
            v-model="formAgent.agent_group_id">
            <el-option v-for="group in agentGroupList" :key="group.value" :label="group.text" :value="group.value"/>
          </el-select>
        </b-form-group>

        <b-form-group
          id="workspace-group"
          label="Workspace:"
          label-for="workspace">
          <b-form-select
            id="workspace"
            disabled
            :value="activeWorkspace._id"
            :options="workspaces"
            required />
        </b-form-group>

        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal v-model="showModalAgentGroup" title="Add Agent Group" hide-footer>
      <b-form @submit.prevent="createAgentGroup" @reset="closeModalAgentGroup">
        <b-form-group
          id="group-name-group"
          label="Group Name:"
          label-for="group-name">
          <b-form-input
            id="group-name"
            type="text"
            v-model="v$.formAgentGroup.name.$model" />
          <b-form-invalid-feedback :state="!v$.formAgentGroup.name.$invalid" v-if="v$.formAgentGroup.name.$errors[0]">
            {{ $t('validation.' + v$.formAgentGroup.name.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="workspace-group"
          label="Workspace:"
          label-for="workspace">
          <b-form-select
            id="workspace"
            disabled
            v-model="v$.formAgentGroup.workspace_id.$model"
            :value="activeWorkspace._id"
            :options="workspaces"
            required />
          <b-form-invalid-feedback :state="!v$.formAgentGroup.workspace_id.$invalid" v-if="v$.formAgentGroup.workspace_id.$errors[0]">
          {{ $t('validation.' + v$.formAgentGroup.workspace_id.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { isEmpty, uniqBy } from 'lodash';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import agentsAPI from '../../../api/agents';
import agentGroupsAPI from '../../../api/agentGroups';
import usersAPI from '../../../api/users';
import avatar from '../../../library/avatar';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'Agents',
  metaInfo: {
    title: 'Agents',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations: {
    formAgentGroup: {
      name: {
        required,
      },
      workspace_id: {
        required,
      },
    },
  },
  data() {
    return {
      loaderStack: 0,
      loader: null,
      userList: [],
      agentGroupList: [],
      agentList: [],
      showModalAgent: false,
      showModalAgentGroup: false,
      search: '',
      page: 1,
      totalRow: 0,
      formAgent: {
        user_id: '',
        agent_group_id: '',
        workspace_id: '',
      },
      formAgentGroup: {
        name: '',
        workspace_id: '',
        company_id: this.$store.state.company.activeCompany.id,
      },
      timeout_search_agent_group: null,
      selected_group: {
        text: '',
      },
      company_id: this.$store.state.company.activeCompany.id,
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.getAgentList();
      },
    },
    workspaces() {
      console.log(this.$store.state.workspace.workspaces.map((workspace) => ({
        text: workspace.name,
        value: workspace._id,
      })));
      return this.$store.state.workspace.workspaces.map((workspace) => ({
        text: workspace.name,
        value: workspace._id,
      }));
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
  },
  mounted() {
    this.getAgentList();
  },
  methods: {
    async addAgent() {
      this.showModalAgent = true;
      this.showLoader();
      await this.getAgentGroupList();
      await this.getUserList();
      this.formAgentGroup.workspace_id = this.activeWorkspace._id;
      this.hideLoader();
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    doSearch() {
      this.page = 1;
      this.getAgentList();
    },
    pageChanged(page) {
      this.page = page;
      this.getAgentList();
    },
    handlerSearchUser(value) {
      clearTimeout(this.timeout_search_user);
      this.timeout_search_user = setTimeout(() => {
        this.getUserList(value);
      }, 1000);
    },
    async getUserList(search = '') {
      await usersAPI.getList(1, search, this.company_id)
        .then(async (res) => {
          await popupErrorMessages(res);
          this.userList = res.data.rows.map((user) => ({
            text: `${user.name} (${user.email})`,
            value: user.id,
            disabled: !isEmpty(user.agents),
          }));
          if (this.userList.find((v) => !v.disabled)) this.formAgent.user_id = this.userList.find((v) => !v.disabled).value;
        });
    },
    handlerSearchAgentGroup(value) {
      clearTimeout(this.timeout_search_agent_group);
      this.timeout_search_agent_group = setTimeout(() => {
        this.getAgentGroupList(value);
      }, 1000);
    },
    async getAgentGroupList(search = '') {
      await agentGroupsAPI.getList(this.activeWorkspace._id, this.company_id, 1, search)
        .then(async (res) => {
          await popupErrorMessages(res);
          const mapped_data = res.data.rows.map((group) => ({
            text: group.name,
            value: group.id,
          }));

          if (!this.formAgent.agent_group_id) {
            this.formAgent.agent_group_id = res.data.rows.find((v) => v.is_default).id;
            this.selected_group = mapped_data.find((v) => v.value === this.formAgent.agent_group_id);
          }

          if (!this.formAgent.agent_group_id || this.selected_group.text.toLowerCase() === 'default') {
            // console.log('if 1');
            this.agentGroupList = mapped_data;
          } else {
            // console.log('else 1');
            this.agentGroupList.push(...mapped_data);
          }
          this.agentGroupList = uniqBy(this.agentGroupList, 'value');
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handlerSelectedGroupAgent(value) {
      this.selected_group = this.agentGroupList.find((v) => v.value === value);
      this.getAgentGroupList();
    },
    async getAgentList() {
      this.showLoader();
      await agentsAPI.getList(this.activeWorkspace._id, this.company_id, this.page, this.search)
        .then(async (res) => {
          await popupErrorMessages(res);
          this.totalRow = res.data.total;
          const mapped_data = res.data.rows.map((agent) => {
            const data = agent;
            data.last_login_at_str = agent.user.last_login_at ? moment(agent.user.last_login_at).format('DD MMM YYYY, HH:mm') : '-';
            data.last_online_at_str = agent.last_online_at ? moment(agent.last_online_at).format('DD MMM YYYY, HH:mm') : '-';
            data.last_offline_at_str = agent.last_offline_at ? moment(agent.last_offline_at).format('DD MMM YYYY, HH:mm') : '-';
            data.image = avatar(agent.user.name, agent.user.email);
            return data;
          });
          this.agentList = mapped_data;
        })
        .catch(() => {});
      this.hideLoader();
    },
    clearForm() {
      this.form = {
        user_id: '',
        workspace_id: this.activeWorkspace._id,
      };
    },
    closeModalAgent() {
      this.showModalAgent = false;
      this.clearForm();
    },
    closeModalAgentGroup() {
      this.showModalAgentGroup = false;
      this.v$.formAgentGroup.$reset();
      this.clearForm();
    },
    async asignUser() {
      this.showLoader();
      this.formAgent.workspace_id = this.activeWorkspace._id;
      this.formAgent.company_id = this.company_id;
      await agentsAPI.asignUser(this.formAgent)
        .then(async (response) => {
          await popupErrorMessages(response);
          this.$message({
            message: this.$t('agents.success.add'),
            type: 'success',
          });
          this.page = 1;
          this.search = '';
          this.closeModalAgent();
          this.getAgentList();
        })
        .catch(() => {});
      this.hideLoader();
    },
    async createAgentGroup() {
      this.v$.formAgentGroup.$touch();
      if (this.v$.formAgentGroup.$error) return;
      this.showLoader();
      this.formAgentGroup.workspace_id = this.activeWorkspace._id;
      await agentGroupsAPI.create(this.formAgentGroup)
        .then(async (response) => {
          await popupErrorMessages(response);
          this.$message({
            message: this.$t('groups.success.add'),
            type: 'success',
          });
          this.page = 1;
          this.search = '';
          this.getAgentGroupList();
          this.closeModalAgentGroup();
        })
        .catch(() => {});
      this.hideLoader();
    },
    async deleteAgent(id) {
      this.$confirm(this.$t('agents.confirm.delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;

            const response = await agentsAPI.delete(id)
              .catch(() => {});
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);

            this.$message({
              message: this.$t('agents.success.delete'),
              type: 'success',
            });

            this.page = 1;
            this.search = '';
            this.getAgentList();
            cb();
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    handlerBtnSetAgentAvail(id, is_current_available) {
      const message = `Set agent availibility status to ${is_current_available ? 'offline' : 'online'}?`;
      this.$confirm(message, this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;

            const response = await agentsAPI.updateAgent(id, {
              status: is_current_available ? 'off' : 'on',
            }).catch(() => {});
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            this.getAgentList();
            this.$message({
              message: 'Agent availibility status has been changed',
              type: 'success',
            });
          }
          cb();
        },
      }).catch(() => {});
    },
  },
};
</script>
